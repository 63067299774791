<template>
  <div>
    <!-- 上半部 -->
    <div class="row mb-1">
      <!-- 按鈕 -->
      <div class="col-md-5 col-12 mb-1">
        <div
          class="dropdown d-inline-block me-3"
          v-if="storeInfo.data.canCheckOut && participantId > 0"
        >
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="CheckOut"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            前往結單
          </button>
          <ul class="dropdown-menu" aria-labelledby="CheckOut">
            <li>
              <router-link
                :to="`/customer/store/${storeId}/checkOutOrder?participantId=${participantId}&status=preCheckout`"
                class="dropdown-item"
                >預購結單</router-link
              >
            </li>
            <li>
              <router-link
                :to="`/customer/store/${storeId}/checkOutOrder?participantId=${participantId}&status=allocatedCheckout`"
                class="dropdown-item"
                >已配結單</router-link
              >
            </li>
          </ul>
        </div>
        <!-- 常用搜尋 -->
        <button
          class="tw-btn tw-btn-secondary me-3"
          @click="frequentlySearched('斷貨')"
        >
          已斷貨訂單
        </button>
        <!-- 進階搜尋 -->
        <button
          class="tw-btn tw-btn-success"
          @click="showModal('advancedSearch')"
        >
          進階搜尋
        </button>
      </div>
      <!-- 訊息 -->
      <div class="col-md-7 col-12 mb-1 fw-bolder">
        <p>
          搜尋條件:
          <span class="badge bg-primary me-1 mb-1">
            訂單狀態:
            {{ recordAdvancedSearchData.singleSelect.orderStatus }}
          </span>
        </p>
        <p>
          <span v-if="recordAdvancedSearchData.timeRange.switch">
            時間區間:
            <span
              v-if="
                recordAdvancedSearchData.timeRange.startTime &&
                recordAdvancedSearchData.timeRange.endTime
              "
              >{{ recordAdvancedSearchData.timeRange.startTime }}~{{
                recordAdvancedSearchData.timeRange.endTime
              }}</span
            >
            <span
              v-if="
                recordAdvancedSearchData.timeRange.startTime &&
                !recordAdvancedSearchData.timeRange.endTime
              "
              >{{ recordAdvancedSearchData.timeRange.startTime }}開始</span
            >
            <span
              v-if="
                !recordAdvancedSearchData.timeRange.startTime &&
                recordAdvancedSearchData.timeRange.endTime
              "
              >{{ recordAdvancedSearchData.timeRange.endTime }}以前</span
            >&ensp;
          </span>
        </p>
      </div>
    </div>
    <!-- dataTable -->
    <div class="tw-container">
      <div class="management-datatable">
        <DataTable
          class="p-datatable-sm"
          :scrollHeight="`${scrollHeight}px`"
          :value="myOrderList"
          dataKey="id"
          :loading="dataTabelLoading"
          :rowHover="true"
          :paginator="true"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rows="20"
          :rowsPerPageOptions="[20, 50, 100]"
          :scrollable="true"
          currentPageReportTemplate="從第 {first} 筆到 第 {last} 筆，總共 {totalRecords} 筆"
          v-model:filters="searchGlobal"
          filterDisplay="row"
          stateStorage="local"
          stateKey="dt-state-customerMyOrder-local"
          sortField="createTime"
          :sortOrder="-1"
        >
          <template #header>
            <TabView></TabView>
            <input
              class="form-control"
              v-model="searchGlobal['global'].value"
              placeholder="表格搜尋: 訂單編號，商品名稱，明細內購買數量，斷貨數量，建立時間"
            />
          </template>
          <!-- 訂單編號 -->
          <Column
            v-if="fieldFilter[0].selected"
            field="id"
            header="訂單編號"
            sortable
          >
            <!-- style="min-width: 80px; max-width: 120px" -->
            <template #body="{ data }">
              <img
                v-if="
                  nowTime - data.createTimeTimeStamp <
                    merchOrderCancellationPeriod &&
                  participantId === data.creatorId
                "
                class="shot-icon me-2"
                src="@/assets/icon/trash-2.png"
                @click="showModal('delOrder', data)"
              />
              <span>{{ data.id }}</span>
            </template>
          </Column>
          <Column
            v-if="fieldFilter[1].selected"
            field="buyerName"
            sortable
            header="建立者"
          >
            <template #body="{ data }">
              <span
                v-if="
                  participantId === data.creatorId ||
                  (slaveId && slaveId === data.creatorId)
                "
                >{{ data.buyerName }}</span
              >
              <span v-else>賣家</span>
            </template>
          </Column>
          <!-- 封面 -->
          <Column
            v-if="fieldFilter[2].selected"
            field=""
            header="封面"
            style="min-width: 60px; max-width: 80px"
          >
            <template #body="{ data }">
              <Image
                class="table-img"
                :imageHash="data.imageHash"
                size="s"
                v-if="data.imageHash"
                :alt="data.imageHash"
              ></Image>
              <img
                class="table-img"
                v-else
                src="@/assets/other-images/noImg.png"
                alt=""
              />
            </template>
          </Column>
          <!-- 商品名稱與款式 -->
          <Column
            v-if="fieldFilter[3].selected"
            field="merchandise.name"
            header="商品名稱與款式"
            sortable
            style="min-width: 250px; max-width: 250px"
          >
            <template #body="{ data }">
              <router-link
                class="mb-1 fw-bolder d-block text-break"
                :to="`/customer/store/${storeId}/orderPage?merchandiseId=${data.merchandiseId}`"
                >{{ data.merchandise.name }}</router-link
              >
              <p class="badge bg-secondary text-break mb-1 me-1">
                {{ data.merchandiseStyle }}
              </p>
              <span
                class="badge bg-danger mb-1 me-1"
                v-if="!data.merchandise.checkOut"
                >不能自行結單</span
              >
              <span
                class="badge bg-warning mb-1 me-1"
                v-if="data.merchandise.startSaleQuantity > 0"
                >此商品所有訂單數量滿{{
                  data.merchandise.startSaleQuantity
                }}單，即可結單</span
              >
              <p class="text-secondary text-break" v-if="data.note">
                備註: {{ data.note }}
              </p>
            </template>
          </Column>
          <!-- 明細 -->
          <Column
            v-if="fieldFilter[4].selected"
            field="quantity"
            header="明細"
            sortable
            style="min-width: 85px"
          >
            <template #body="{ data }">
              <span title="數量" style="cursor: help">{{ data.quantity }}</span>
              ×
              <span title="價格" style="cursor: help">{{ data.price }}</span>
            </template>
          </Column>
          <!-- 斷貨狀態 -->
          <Column
            v-if="fieldFilter[5].selected"
            field="soldOutQuantity"
            header="斷貨狀態"
            sortable
            style="min-width: 75px"
          >
            <template #body="{ data }">
              <p class="text-danger" v-if="data.soldOutQuantity > 0">
                <p>斷貨數: {{ data.soldOutQuantity }}</p>
                <p>原訂單: {{ data.soldOutQuantity + data.quantity }}</p>
              </p>
              <p v-else>未斷貨</p>
            </template>
          </Column>
          <!-- 到貨狀態 -->
          <Column
            v-if="fieldFilter[6].selected"
            field="arrivalStatus"
            header="到貨狀態"
            sortable
            style="min-width: 87px"
          >
            <template #body="{ data }">
              <p class="text-success" v-if="data.arrivalStatus">已到貨</p>
              <p class="text-danger" v-else>
                未到貨({{ data.quantity - data.allocatedQuantity }})
              </p>
            </template>
          </Column>
          <!-- 結單狀態 -->
          <Column
            v-if="fieldFilter[7].selected"
            field="checkOutStatus"
            header="結單狀態"
            sortable
            style="min-width: 87px"
          >
            <template #body="{ data }">
              <p class="text-success" v-if="data.checkOutStatus">已結單</p>
              <p class="text-danger" v-else>
                未結單({{ data.quantity - data.checkOutQuantity }})
              </p>
            </template>
          </Column>
          <!-- 付款狀態 -->
          <Column
            v-if="fieldFilter[8].selected"
            field="paymentStatus"
            header="付款狀態"
            sortable
            style="min-width: 87px"
          >
            <template #body="{ data }">
              <p class="text-success" v-if="data.paymentStatus">已付款</p>
              <p class="text-danger" v-else>
                未付款({{ data.quantity - data.paymentQuantity }})
              </p>
            </template>
          </Column>
          <!-- 出貨狀態 -->
          <Column
            v-if="fieldFilter[9].selected"
            field="shippedStatus"
            header="出貨狀態"
            sortable
            style="min-width: 87px"
          >
            <template #body="{ data }">
              <p class="text-success" v-if="data.shippedStatus">已寄出</p>
              <p class="text-danger" v-else>
                未寄出({{ data.quantity - data.shippingQuantity }})
              </p>
            </template>
          </Column>
          <!-- 建立時間 -->
          <Column
            v-if="fieldFilter[10].selected"
            field="createTime"
            header="建立時間"
            sortable
            :sortOrder="-1"
            style="min-width: 150px"
          >
          </Column>
          <template #empty>
            <p class="text-center fw-bolder text-primary">
              目前尚未有任何資料 0..0
            </p>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
  <!-- 刪除訂單的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="delOrderModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header alert alert-danger">
          <h5
            class="modal-title tw-border-start fw-bolder border-danger text-danger"
          >
            刪除
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          是否刪除<span class="fw-bolder text-warning">
            訂單編號：
            <span v-for="data in selectItems" :key="data.id"
              >{{ data.id }}&ensp;</span
            >
          </span>
          訂單 ?
          <span class="fw-bolder text-danger">注意! 此步驟無法恢復</span>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="delOrder">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 進階搜尋的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="advancedSearchModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">進階搜尋</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 時間區間 -->
          <div class="mb-3">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_TimeSwitch"
                v-model="advancedSearchData.timeRange.switch"
              />
              <label class="form-check-label" for="AS_TimeSwitch"
                >時間區間</label
              >
            </div>
            <!-- 時間 -->
            <div class="row mb-3" v-if="advancedSearchData.timeRange.switch">
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_StartTime"
                    placeholder="起始時間"
                    v-model="advancedSearchData.timeRange.startTime"
                  />
                  <label for="AS_StartTime" class="mb-2">起始時間</label>
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_EndTime"
                    placeholder="結束時間"
                    v-model="advancedSearchData.timeRange.endTime"
                  />
                  <label for="AS_EndTime" class="mb-2">結束時間</label>
                </div>
              </div>
            </div>
          </div>
          <!-- 篩選條件 -->
          <div class="border mb-3 p-3">
            <p class="tw-border-start fw-bolder">篩選條件</p>
            <hr />
            <ul class="list">
              <li
                class="row border-bottom"
                v-for="data in singleSelectsArray"
                :key="data.id"
              >
                <div class="col-3 my-2">{{ data.key }}</div>
                <div class="col-9">
                  <div
                    class="select-radio my-2"
                    v-for="select in data.value"
                    :key="select.id"
                  >
                    <label>
                      <input
                        type="radio"
                        :name="data.id"
                        :value="select.value"
                        v-model="advancedSearchData.singleSelect[data.id]"
                      />
                      <span class="radio-style">{{ select.value }}</span>
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="advancedSearch(true)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// dataTable
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import Row from 'primevue/row';                     //optional for row
import { FilterMatchMode } from 'primevue/api';
// api
import { parseTokenToGetTheParticipant } from '@/methods/API/parseTokenToGetTheParticipant.js'

export default {
  components: {
    DataTable, Column, ColumnGroup, Row
  },
  data() {
    return {
      // modal
      delOrderModal: {},
      advancedSearchModal: {},
      // data
      serverToken: '',
      storeId: 0,
      participantId: 0,
      slaveId: 0,
      // dataTable
      scrollHeight: 0,
      myOrderList: [],
      dataTabelLoading: false,
      searchGlobal: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS
        }
      },
      // 賣場資訊
      storeInfo: {
        loading: false,
        data: []
      },
      // 賣場設置下單後可刪除訂單時間
      merchOrderCancellationPeriod: 0,
      nowTime: new Date(),
      catchTime: '',
      // 選到的訂單
      selectItems: [],
      // 進階搜尋
      advancedSearchData: {
        timeRange: {
          switch: false,
          startTime: null,
          endTime: null
        },
        singleSelect: {
          orderStatus: '未完成',
        }
      },
      recordAdvancedSearchData: {},
      // 單選篩選陣列
      singleSelectsArray: [],
      // 常用搜尋陣列
      frequentlySearchData: [],
      currentSearchIndex: 0, // 用於常用搜尋 tab 的 select 效果
    }
  },
  created() {
    this.initialization()
    this.getPrefer()
    this.getStoreInfo() 
    this.getParticipantId()
    this.updateTime('open')
  },
  mounted() {
    this.createModals(['delOrderModal', 'advancedSearchModal'])
  },
  unmounted() {
    // 將 dataTable 的紀錄清除
    localStorage.removeItem('dt-state-customerMyOrder-local')
    this.updateTime('close')
  },
  computed: {
    // 偏好 > 表格欄位
    fieldFilter() {
      return this.$store.state.Prefer.p_customerMerchorder.fieldFilter
    },
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      // 紀錄 dataTabel 高度
      this.scrollHeight = window.innerHeight - 210
      // 將 dataTable 的紀錄清除
      localStorage.removeItem('dt-state-customerMyOrder-local')
      // 紀錄進階搜尋單選條件
      this.singleSelectsArray = [
        {
          id: 'orderStatus',
          key: '訂單狀態',
          value: [
            {
              id: 'A1',
              value: '未完成'
            },
            {
              id: 'A2',
              value: '已完成'
            },
            {
              id: 'A3',
              value: '全部'
            },
            {
              id: 'A4',
              value: '斷貨'
            },
          ]
        },
      ]
      // 多紀錄進階搜尋
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
    },
    // 開啟 modal
    showModal(status, item) {
      if (status === 'delOrder') {
        this.selectItems = []
        this.selectItems.push(item)
        this.delOrderModal.show()
      } else if (status === 'advancedSearch') {
        // 進階搜尋
        this.advancedSearchData = JSON.parse(JSON.stringify(this.recordAdvancedSearchData))
        this.advancedSearchModal.show()
      }
    },
    // 更新時間
    updateTime(state) {
      const vm = this
      if (state === 'open') {
        this.catchTime = setInterval(function() { 
          vm.nowTime = new Date() * 1
        }, 1000)
      } else {
        clearInterval(this.catchTime)
      }
    },
    // 計算預設時間區間
    computedD4TimeRange() {
      this.advancedSearchData.timeRange.endTime = this.$methods.moment().format('YYYY-MM-DD')
      this.advancedSearchData.timeRange.startTime = this.$methods.moment().subtract(90, 'days').format('YYYY-MM-DD')
    },
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.storeId,
        serverToken: this.serverToken
      })
    },
    // 取得賣場資訊
    getStoreInfo(methodsArray = []) {
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const getDataName = `{${methodsArray.toString()}}`
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: getDataName
        }
      ]
      this.storeInfo = {
        loading: true,
        data: {}
      },
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.storeInfo = {
              loading: false,
              data: storeInfo
            }
            vm.merchOrderCancellationPeriod = storeInfo.merchOrderCancellationPeriod * 1000 * 60
            console.log(vm.merchOrderCancellationPeriod)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 取得目前帳號的 participantId 與 slaveId
    getParticipantId() {
      this.$methods.switchLoading('show')
      this.dataTabelLoading = true
      const result = parseTokenToGetTheParticipant(this.storeId, this.serverToken)
      result.then(res => {
        console.log(res)
        if (res.code === '200') {
          this.participantId = res.data.id
          this.slaveId = res.data.slaves.length > 0 ? res.data.slaves[0].id : 0
          this.computedD4TimeRange()
          this.advancedSearch()
        }
      }).catch(err => {
        console.log(err.responseJSON)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
        this.dataTabelLoading = false
      })
    },
    // todo 常用搜尋
    frequentlySearched(searchStatus) {
      // 紀錄要變更的單選項目
      let singleSelectOption = null
      // 還原所有單選選項預設值
      this.advancedSearchData.singleSelect.orderStatus = "未完成"
      // 判斷是哪個單選項目要變更
      if (searchStatus === '斷貨') singleSelectOption = 'orderStatus'
      // 判斷是否有未處理到的項目，提醒工程師用
      if (!singleSelectOption) return alert('沒有此搜尋選項')
      this.advancedSearchData.singleSelect[singleSelectOption] = searchStatus
      this.advancedSearch()
    },
    // 進階搜尋
    advancedSearch(closeModal) {
      this.dataTabelLoading = true
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
      let participantIds = [this.participantId]
      if (this.slaveId) participantIds.push(this.slaveId)
      const query = {"columns":[{"columnName":"id","siteObjectType":26}],"distinct":true,"group":{"groups":[{"column":{"columnName":"storeId","siteObjectType":26},"operator":{"operator":"=","type":1,"value":this.storeId}},{"column":{"columnName":"buyerId","siteObjectType":26},"operator":{"operator":"=","type":3,"value":participantIds}}],"operator":1},"siteObjectType":26,"sqlCommandType":1}
      // 時間區間
      if (this.advancedSearchData.timeRange.switch) {
        if (this.advancedSearchData.timeRange.startTime) {
          const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.startTime} 00:00:00`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 26
            },
            operator: {
              operator: ">=",
              type: 1,
              value: {
                name: `from_unixtime(${startTime})`
              }
            }
          })
        }
        if (this.advancedSearchData.timeRange.endTime) {
          const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.endTime} 23:59:59`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 26
            },
            operator: {
              operator: "<=",
              type: 1,
              value: {
                name: `from_unixtime(${endTime})`
              }
            }
          })
        }
      }
      // 斷貨
      if (this.advancedSearchData.singleSelect.orderStatus === "斷貨") {
        query.group.groups.push({
          column: {
            columnName: 'SoldOutQuantity',
            siteObjectType: 26
          },
          operator: {
            operator: ">",
            type: 1,
            value: 0
          }
        })
      } else if (this.advancedSearchData.singleSelect.orderStatus === "未完成" || this.advancedSearchData.singleSelect.orderStatus === "已完成") {
        query.group.groups.push({
          column: {
            columnName: 'quantity',
            siteObjectType: 26
          },
          operator: {
            operator: ">",
            type: 1,
            value: 0
          }
        })
      }
      if (closeModal) this.advancedSearchModal.hide()
      this.getOrderList(query)
    },
    // 取得訂單列表
    getOrderList(query) {
      const vm = this
      const getOrderListApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          query: query,
          methods: '{getBuyer{getUser},getCheckOutQuantity,getPaymentQuantity,getShippingQuantity,getMerchandiseStyles,getMerchandise{getMerchandisePictureLinks}}'
        }
      ]
      this.myOrderList = []
      $.ajax({
        type: 'POST',
        async: true,
        url: getOrderListApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const myOrderList = res.data[0].objects
            vm.orderListMap(myOrderList)
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 整理訂單列表
    orderListMap(myOrderList) {
      myOrderList.forEach(item => {
        // 原資料
        const object = {
          allocatedQuantity: item.allocatedQuantity,
          arrivalStatus: null,
          buyerId: item.buyerId,
          buyerName: item.buyer.user.name,
          checkOutQuantity: item.checkOutQuantity,
          checkOutStatus: null,
          class: item.class,
          createTime: this.$methods.moment(item.createTime).format("YYYY-MM-DD HH:mm:ss"),
          createTimeTimeStamp: item.createTime,
          creatorId: item.creatorId,
          id: item.id,
          imageHash: '',
          merchandise: item.merchandise,
          merchandiseId: item.merchandiseId,
          merchandiseStyle: '',
          merchandiseStyles: item.merchandiseStyles,
          note: item.note,
          paymentQuantity: item.paymentQuantity,
          paymentStatus: null,
          price: item.price,
          quantity: item.quantity,
          shippedStatus: null,
          shippingQuantity: item.shippingQuantity,
          soldOutQuantity: item.soldOutQuantity,
          source: item.creationType,
          sourceMessage: item.sourceMessage,
          stockId: item.stockId,
          storeId: item.storeId,
        }
        // 需特別處理
        // 到貨狀態
        object.arrivalStatus = object.quantity === object.allocatedQuantity && object.quantity > 0
        // 結單狀態
        object.checkOutStatus = object.quantity === object.checkOutQuantity && object.quantity > 0
        // 付款狀態
        object.paymentStatus = object.quantity === object.paymentQuantity && object.quantity > 0
        // 出貨狀態
        object.shippedStatus = object.quantity === object.shippingQuantity && object.quantity > 0
        // 商品圖片
        object.merchandise.merchandisePictureLinks.some(picture => {
          if (picture.front) return object.imageHash = picture.imageHash
        })
        // 商品款式
        let merchandiseStyle = []
        object.merchandiseStyles.forEach(style => {
          merchandiseStyle.push(style.name)
        })
        object.merchandiseStyle = merchandiseStyle.join()

        // 進階搜尋 > 訂單狀態 (配合欄位顯示，欄位有顯示才會加入判斷)
        let matchOrderStatus = false
        let checkArray = []
        if (this.fieldFilter[6].selected) checkArray.push('arrivalStatus')
        if (this.fieldFilter[7].selected) checkArray.push('checkOutStatus')
        if (this.fieldFilter[8].selected) checkArray.push('paymentStatus')
        if (this.fieldFilter[9].selected) checkArray.push('shippedStatus')
        switch (this.advancedSearchData.singleSelect.orderStatus) {
          case '未完成':
            const unFinishA = checkArray.some(item => {
              return !object[item]
            })
            if (unFinishA) matchOrderStatus = true
            break;
          case '已完成':
            const unFinishB = checkArray.some(item => {
              return !object[item]
            })
            if (!unFinishB) matchOrderStatus = true
            break;
          case '全部':
            matchOrderStatus = true
            break;
          case '斷貨':
            matchOrderStatus = true
        }

        if (matchOrderStatus) this.myOrderList.push(JSON.parse(JSON.stringify(object)))
      })
      this.dataTabelLoading = false
    },
    // 刪除訂單
    delOrder() {
      if (this.participantId !== this.selectItems[0].creatorId) return this.SweetAlert('other', '賣家幫您下的單您是無法刪除的喔')
      this.$methods.switchLoading('show')
      const vm = this
      const delOrderApi = `${process.env.VUE_APP_API}/merchOrder/delete`
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData()
      data.append("merchOrderIds", this.selectItems[0].id)
      $.ajax({
        type: 'DELETE',
        async: true,
        url: delOrderApi,
        data: data,
        headers: header,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.advancedSearch()
            vm.delOrderModal.hide() 
          } else {
            // vm.SweetAlert(res.code, res.message)
            console.log(res.code)
            if (res.code === '206') {
              let message = {
                icon: 'warning',
                title: {
                  show: false,
                  value: '',
                },
                text: {
                  show: true,
                  value: res.errorData[0].message
                },
                timer: 10000
              }
              vm.SweetAlert('setByMyself', message)
              vm.delOrderModal.hide() 
            }
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 0px !important;
}
</style>